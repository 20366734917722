@use 'tailwindcss/base';
@use 'tailwindcss/components';
@use 'tailwindcss/utilities';
@use 'swiper/scss';
@use 'styles/mixins' as *;
@use 'styles/material' as *;
@use 'styles/vars' as *;

html,
body {
  height: 100%;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
p {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
}

.primary-bg {
  background-color: #3f51b5;
}

.mat-mdc-button-base {
  min-height: 40px;
}

.link {
  color: #3f51b5;
  text-decoration: underline;
}

.error-snackbar {
  $text-color: hsl(359, 91%, 18%);

  background-color: hsl(360, 96%, 84%);
  color: $text-color;
  border: 2px solid hsl(359, 92%, 26%);
  border-radius: 4px;

  .mat-mdc-snack-bar-action .mat-mdc-button-base {
    color: $text-color;
  }
}

.full-row {
  grid-column: 1 / -1;
}

.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  &::-webkit-scrollbar {
    background: transparent; /* Chrome/Safari/Webkit */
    width: 0px;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-mdc-radio-button.no-label .mat-radio-label .mat-radio-label-content {
  display: none;
}
.promo-list.mat-mdc-list-base .mat-mdc-list-item .mat-list-item-content,
.promo-list.mat-list-base[dense] .mat-list-item .mat-list-item-content {
  padding: 0;
}

.h-default {
  height: $defaultAppHeight;
}
