$defaultAppHeight: calc(100% - 56px);
$bonafontOrange: #ff734d;

$bonafont-palette: (
  50: #faeae8,
  100: #fecdbf,
  200: #fead96,
  300: #ff8d6d,
  400: #ff734d,
  500: #ff5c30,
  600: #f4562c,
  700: #e64f27,
  800: #d84823,
  900: #bf3b1b,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: rgba(black, 0.87),
    700: rgba(black, 0.87),
    800: white,
    900: white
  )
);

$bonafont-complementary-palette: (
  50: #dbf6ff,
  100: #a0e8fe,
  200: #4dd8ff,
  300: #00c8ff,
  400: #00bbff,
  500: #00aeff,
  600: #00a0f2,
  700: #008ddf,
  800: #007ccc,
  900: #005bab,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: rgba(black, 0.87),
    700: rgba(black, 0.87),
    800: white,
    900: white
  )
);
